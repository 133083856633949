import React, { useState, useContext, useEffect } from "react";
import "./Suscripcion.css";
import CardModalChange from "../../components/cardChange/CardModalChange";
import { useNavigate } from "react-router-dom";
import InfoContext from "../../Context";
import { loadAnimation } from "lottie-web";
import { defineLordIconElement } from "lord-icon-element";
import { Alert, Button } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import axios from "axios";
import { postInfoUsers } from "../../services/consumeApi";
import Modal from "../ModalSuscripcion/Modal"
import AlertEdit from "../alert/AlertEdit";
import Chip from '@mui/material/Chip';

const Suscripcion = () => {
  const { users, setUsers } = useContext(InfoContext);
  const navigate = useNavigate();
  const [dialogSuccess, setDialogSuccess] = useState(false);
  const [tableCobros, setTableCobros] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [openTable, setOpenTable] = useState(false);
  const [valueUser, setValueUsers] = useState({})
  const [editEmail, setEditEmail] = useState(undefined)
  const [editPhone, setEditPhone] = useState(undefined)
  const [editMonto, setEditMonto] = useState(undefined)
  const [valueDate, setValue] = useState();
  const [montoError, setMontoError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openAlertFailed, setOpenAlertFailed] = useState(false);

  const [header, setHeader] = useState(false)
  const [colorIcono, setColorIcono] = useState(false)
  document.body.style.backgroundColor = `#${users.colorGlobal}`

  const handleClickOpen = (users) => {
    setOpenEdit(true);
    setValueUsers(users);
    setMontoError('')
    setEditMonto(users.amount)
    setValue(users.datepaymentauto)

  };

  const navigateReturn = () => {
    sessionStorage.clear();
    navigate("/");
  };
  const handle = () => {
    setOpenSuccess(false);
    setValueUsers(users)
  }

  const closeModalEdit = () => {
    setOpenEdit(false)
  }

  const closeTable = () => {
    setOpenTable(false)
  }

  const servicesCobros = async (item) => {
    setOpenTable(true)
    const response = await axios.post(process.env.REACT_APP_URL, {
      "id": item.subscription,
      "action": "cobros"
    });
    if (response.status === 200) {
      response.data.cobros.sort((a, b) => {
        let dateA = new Date(a.fechacobro.split("-").reverse().join("-"));
        let dateB = new Date(b.fechacobro.split("-").reverse().join("-"));
        return dateB - dateA;
      });
      setTableCobros(response.data)
    }
  }
  const Update = async () => {
    var date = new Date(valueDate);
    const formatDate = (date) => {
      let formatted_date = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
      return formatted_date;
    }

    const replaceMatriz = {
      "client": valueUser.client,
      "subscription": valueUser.subscription,
      "phone": editPhone === undefined ? valueUser.phone : editPhone,
      "email": valueUser.email,
      "amount": editMonto === undefined ? valueUser.amount : editMonto,
      "datepaymentauto": formatDate(date) === undefined ? valueUser.fechacobroautomatico : formatDate(date),
      "tkp": users.tkpUrl,
    }
    const res = await axios.put(process.env.REACT_APP_URL, replaceMatriz)
    if (res.status === 200) {
      const resUser = await postInfoUsers(users.tkpUrl, users.validUrl, replaceMatriz.email, users.cardDigits,);

      if (resUser.status === 200) {
        const initialValues = {
          emailUrl: users.email,
          tkpUrl: users.tkpUrl,
          validUrl: users.validUrl,
          cardDigits: users.cardDigits,
          data: resUser.data.subscriptions,
          colorGlobal: users.colorGlobal
        };
        let dataSuscripcion = { 'data': initialValues }
        setUsers(initialValues)
        sessionStorage.setItem('dataSuscripcion', JSON.stringify(dataSuscripcion))
        setOpenEdit(false);
      }
    } else if (res.status === "failed") {
      setOpenAlertFailed(true)
    }

    if (res.data.data.status_email === "success") {
      setDialogSuccess(true)
    }
    else {
      setOpenSuccess(true);
    }
  }
  const handleSuccess = () => {
    navigateReturn();
  }

  const valideRango = (number) => {
    setEditMonto(number)
    if (number < 1000 || number > 300000) {
      setMontoError('El monto debe ser entre 1000 a 300000');
    } else {
      setMontoError('')
    }
  }
  const valideEmail = (event) => {
    setEditEmail(event.target.value)
    const valide = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
    if (!valide.test(event.target.value)) {
      setEmailError('Correo inválido');
    } else {
      setEmailError('')
    }
  }

  const valideStatus = (estatus) => {
    if (estatus === 'activa') {
      return 'success'
    } else if (estatus === 'suspendida') {
      return 'warning'
    } else {
      return 'error'
    }
  }
  const valideLabel = (estatus) => {
    if (estatus === 'activa') {
      return 'activa'
    }
    if (estatus === 'suspendida') {
      return 'suspendida'
    }
  }



  const stringValideStatus = (estatus) => {
    if (estatus === 'activa') {
      return { title: 'Actualizar tarjeta', disabled: false }
    }
    if (estatus === 'suspendida') {
      return { title: 'Reactivar suscripción', disabled: false }
    }

  }

  useEffect(() => {
    const userInfoObject = sessionStorage.getItem("dataSuscripcion");
    window.addEventListener('scroll', () => {
      let header = document.querySelector('#headerEffect');
      if (header) {
        setHeader(true)
        header.classList.toggle('strick', window.scrollY > 0);
      }
      if (window.scrollY > 0) {
        setColorIcono(true)
      } else {
        setColorIcono(false)
      }
    });
    if (!userInfoObject) {
      navigate("/");
    }
  }, [navigate, users.data]);
  defineLordIconElement(loadAnimation);

  return (
    <>
      <div className="container">
        <div className="headerColor" id="headerEffect">
          <div className="header">
            <div className="headerSuscripcion">
              <h1>Membresía</h1>
              <div className="salir">
                <Button onClick={navigateReturn}>
                  <lord-icon
                    src="https://cdn.lordicon.com/ztqvbxrm.json"
                    trigger="hover"
                    colors={colorIcono ? "primary:#ebe6ef" : "primary:#4D39E9"}
                    state="hover"
                    scale="60"
                  ></lord-icon>
                </Button>
              </div>
            </div>
            <hr />
          </div>
        </div>
        <div className="cardContainer">
          {users?.data?.sort((a, b) => b.ordered - a.ordered)
            .map((item, index) => (
              <div className="cardSuscripcion" key={index}>
                <div className="icon">
                  <Tooltip title="Cambiar tarjeta">
                    <Button className="iconTarjet" target="_black" href={item.url} disabled={stringValideStatus(item.estatus)?.disabled}>
                      <lord-icon
                        src="https://cdn.lordicon.com/ndjyfjgm.json"
                        trigger="hover"
                        colors={!item.estatus === 'active' ? "primary:#848484,secondary:#b4b4b4,tertiary:#ebe6ef" : "primary:#3a3347,secondary:#4bb3fd,tertiary:#ebe6ef"}
                        stroke="80"
                        scale="60"
                      ></lord-icon>
                      <div className="divTitle" >
                        <p className="titleIcon">{stringValideStatus(item.estatus)?.title} </p>
                        <p className="subTitleIcon"><strong><i>En caso de pérdida, vencimiento o robo.</i></strong></p>
                      </div>
                    </Button>
                  </Tooltip>
                  <Tooltip title="Revisa el historial de tus aportes">
                    <Button className="iconTarjet" onClick={() => servicesCobros(item)}>
                      <lord-icon
                        src="https://cdn.lordicon.com/ulpqzbwq.json"
                        trigger="hover"
                        colors="primary:#3a3347,secondary:#4f1091,tertiary:#4f1091,quaternary:#ebe6ef"
                        scale="60"
                        stroke="80"
                      >
                      </lord-icon>
                      <p className="titleIcon">Historial Aportes</p>
                    </Button>
                  </Tooltip>
                  <Tooltip title="Edita tus Datos Personales" >
                    <Button className="iconTarjet" onClick={() => handleClickOpen(item)} disabled={stringValideStatus(item.estatus)?.disabled}>
                      <lord-icon
                        src="https://cdn.lordicon.com/rfbqeber.json"
                        trigger="hover"
                        colors="primary:#e8e230,secondary:#ebe6ef,tertiary:#4f1091"
                        scale="70"
                      >
                      </lord-icon>
                      <p className="titleIcon">Actualiza monto de tu aporte</p>
                    </Button>
                  </Tooltip>
                </div>
                <div className="suscripcion">
                  <p className="planTitle" key={item.id}>Estado:</p>
                  <Chip className="chip" label={`${valideLabel(item.estatus)}`} color={`${valideStatus(item.estatus)}`} />
                </div>
                <div className="detalles">
                  <table className="tableContent">
                    <tbody>
                      <tr className="itemSuscripcion">
                        <th className="titleSuscripcion">Fecha Registro</th>
                        <td className="descripcion">{item.created_at}</td>
                      </tr>
                      <tr className="itemSuscripcion">
                        <th className="titleSuscripcion">Tipo de Tarjeta</th>
                        <td className="descripcion">{item.card_type}</td>
                      </tr>
                      <tr className="itemSuscripcion">
                        <th className="titleSuscripcion">Tarjeta</th>
                        <td className="descripcion">{item.last_4_digits}</td>
                      </tr>
                      <tr className="itemSuscripcion">
                        <th className="titleSuscripcion">Plan</th>
                        <td className="descripcion" key={item.id}>
                          <td className="descripcion">{item.plan}</td>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p className="pcorreo" ><strong>Si quieres darte de baja, escríbenos a <i>socios@ciperchile.cl</i></strong></p>
              </div>
            )
            )}

          <Modal
            isOpen={openEdit}
            isCloseEdit={closeModalEdit}
            UpdateEdit={Update}
            SubmitData={handleClickOpen}
            dataUser={valueUser}
            validateEmail={valideEmail}
            emailUser={editEmail}
            phoneUser={editPhone}
            montoUser={editMonto}
            errorEmail={emailError}
            validateMonto={valideRango}
            errorMonto={montoError}
            phoneEdit={setEditPhone}
            date={valueDate}
            setDate={setValue}
          />
          <CardModalChange
            isOpenTable={openTable}
            isClose={closeTable}
            servicesCobros={tableCobros}
          />
          <AlertEdit
            UpdateEdit={Update}
            UpdateSuccess={handle}
            openModalUpdateSuccess={openSuccess}
            editEmailSuccess={handleSuccess}
            openEditEmail={dialogSuccess}
            openUpadateFailed={openAlertFailed}
          />
        </div>
      </div>
    </>
  );
};
export default Suscripcion;